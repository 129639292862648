* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

input, select {
  font-size: 14px !important;
}

td,
th {
  font-family: "Montserrat", sans-serif !important;
}

td {
  font-size: 12px !important;
}
th {
  font-size: 13px !important;
}